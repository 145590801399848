/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import Panel from '../components/Panel'
import Hamburger from '../components/Hamburger/Hamburger'
import FeedbackForm from '../components/Form/FeedbackForm'
import ATLayout from '../components/ATLayout'
import SEO from '../components/seo'

import './feedback.css'
import 'animate.css'
import 'magic.css/dist/magic.min.css'

const check = require('../images/check.svg');
const forward = require('../images/forward-gray.svg');

class FeedbackPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			started: 0,
			animation: 'fadeIn',
			isMenuOpen: false,
			completed: false,
		}

		this.handleOnHamburgerClick = this.handleOnHamburgerClick.bind(this);

		this.handleOnBackClick = this.handleOnBackClick.bind(this);
        this.handleOnBackwardMouseOver = this.handleOnBackwardMouseOver.bind(this);
		this.handleOnBackwardMouseOut = this.handleOnBackwardMouseOut.bind(this);
		
		this.handleOnStartClick = this.handleOnStartClick.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleOnHamburgerClick(event) {
		let imo = this.state.isMenuOpen;
		this.setState({
			isMenuOpen: !imo
		});
	}

    handleOnBackClick(event) {
        event.preventDefault();
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState({ animation: 'fadeIn', started: 0 });
        }, 500);
    }

    handleOnBackwardMouseOver(event) {
        event.currentTarget.querySelector('img').src = require('../images/backward-orange.png');
    }

    handleOnBackwardMouseOut(event) {
        event.currentTarget.querySelector('img').src = require('../images/backward-gray.png');
    }

	handleOnStartClick(event) {

		let self = this;
		self.setState({
			started: 1
		});

		setTimeout(function(){
			self.setState({
				started: 2
			});

			setTimeout(function(){
				self.setState({
					started: 3
				});
			}, 1000);
		}, 2000)
	}

	handleSubmit(event) {
		event.preventDefault();
		let self = this;
		self.setState({ animation: 'fadeOut' });
		setTimeout(function () {
			self.setState({ animation: 'fadeIn', selectedForm: '', completed: true });
		}, 500);
	}

	render() {

		return (
			<ATLayout title="Feedback" isMenuOpen={this.state.isMenuOpen}>
				<SEO title="Feedback • Alfatauri Studio" relPath="feedback"
					description="Grazie per aver collaborato con noi. Facci sapere ciò che pensi di noi." />
				<div className="at-page">
					<div className="container">
						{this.state.started === 0 ?
							<div className="row">
								<div className="col-7 offset-2">
									<h4 className="text-left mb-3">Ti ringraziamo per aver collaborato con noi<span className="orange">.</span></h4>
									<p className="mb-5">Ti ruberemo solo 3 minuti</p>

									<button className="start" type="button" onClick={this.handleOnStartClick}>Inizia</button>
								</div>
							</div>
							: this.state.started === 1 || this.state.started === 2 ?
							<div className="row">
								<div className={"col-6 offset-3 thankyou magictime " + (this.state.started === 1 ? 'swashIn' : this.state.started === 2 ? 'vanishOut' : '')}>
									<h4>Il tuo parere per noi è importante<span className="orange">.</span></h4>
								</div>
							</div>
							:
							<Panel className="py-4">
								<div className={'animated ' + this.state.animation}>
									<FeedbackForm key="feedbackForm"
										onBackClick={this.handleOnBackClick} onSubmit={this.handleSubmit}
										onBackMouseOver={this.handleOnBackwardMouseOver} onBackMouseOut={this.handleOnBackwardMouseOut} />

									{/* {this.state.completed && (
									<React.Fragment>
										<div className="row">
											<div className="col-12 ml-4 mb-4">
												<button className="btn backward-arrow-container p-0" onClick={this.handleBackClick}
													onMouseOver={this.handleBackwardMouseOver} onMouseOut={this.handleBackwardMouseOut}>
													<img className="backward-arrow" alt="Backward form arrow" src={require('../images/backward-gray.png')} />
												</button>
											</div>
										</div>
										<div className="row justify-content-center">
											<div className="col-8 my-5 text-center">
												<img src={check} style={{ width: "50px", height: "50px" }} alt="Form completed" />
												<p className="completed-text my-4">
													<strong>Ben fatto!</strong>
													<br />Ti risponderemo ASAP
                                            </p>
											</div>
										</div>
									</React.Fragment>
								)} */}
								</div>
							</Panel>}
					</div>

					<Hamburger open={this.state.isMenuOpen} //className={this.props.isMenuOpen ? 'open' : ''}
						onClick={this.handleOnHamburgerClick} />
				</div>
			</ATLayout>
		);
	};
}

export default FeedbackPage;