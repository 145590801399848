import React from 'react'

import './Button.css'

const Button = (props) => {
    return (
    <button type="button" id={props.id} className={props.className} style={props.style}
    onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}
    onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut}>
        {props.children}
    </button>);
}

export default Button;