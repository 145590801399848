import React from 'react';
// import styled, { keyframes } from 'styled-components';
// import { fadeIn } from 'react-animations';

// const StyledPanel = styled.div`
// display: block;
// position: relative;
// overflow: hidden;
// background-color: white;
// box-shadow: 0 3px 14px 0px rgba(0,0,0,.12);
// border: none;
// border-radius: 5px
// `;

// const fadeInAnimation = keyframes`${fadeIn}`;

const Panel = (props) => {

    // const PanelPage = styled.div`
    //     border: none;
    //     animation: 1s ${fadeInAnimation};
    // `;

    return (
        <div id={props.id} className={"panel " + props.className}
        style={{display: "block", position: "relative", overflow: "hidden",
            backgroundColor: "white", boxShadow: "0 3px 14px 0px rgba(0,0,0,.12)",
            border: "none", borderRadius: "5px"}}>
            {props.children}
        </div>
        
        // <StyledPanel>
        //     <PanelPage id={props.id} className={"panel " + props.className} >
        //         {props.children}
        //     </PanelPage>
        // </StyledPanel>
    );
}

export default Panel;