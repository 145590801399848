import React from 'react'

import Button from '../Button/Button'

import './ATSliderDots.css'

class ATSliderDots extends React.Component {
    render() {
        const length = this.props.dotsNumber ? parseInt(this.props.dotsNumber, 10) : 0;

        const dots = [...Array(length).keys()].map((d, i) =>
            <Button key={"at-slider-dot-" + d} onClick={() => this.props.onDotClick(i)}
                className={"at-slider-dot at-slider-dot-" + i + ' ' + (i === this.props.activeDot ? 'active' : '')}
                style={{width: this.props.size, height: this.props.size}} />
        )

        return (
            <div className={"at-slider-dots " + (this.props.horizontal ? 'at-slider-dots-horizontal' : '')} style={this.props.style}>
                {dots}
            </div>
        );
    }
}

export default ATSliderDots;