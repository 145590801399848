import React from 'react'

import Button from '../Button/Button'
import ATSliderDots from '../Input/ATSliderDots';

import 'rc-slider/assets/index.css'
import 'animate.css'

class FeedbackForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            animation: 'fadeIn',
            error: ' ',
			page: 1,
			total: 5,
			planning: 5,
			communication: 5,
			comment: '',
            width: window.innerWidth
        };

        this.nextPage = this.nextPage.bind(this);
		this.handleOnBackClick = this.handleOnBackClick.bind(this);
		
        this.handleOnTotalChange = this.handleOnTotalChange.bind(this);
        this.handleOnPlanningChange = this.handleOnPlanningChange.bind(this);
        this.handleOnCommunicationChange = this.handleOnCommunicationChange.bind(this);
        this.handleOnCommentChange = this.handleOnCommentChange.bind(this);

        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    handleOnWindowSizeChange() {
        this.setState({ width: window.innerWidth });
	};
	
    handleOnTotalChange(n) {
        this.setState({ total: n });
    }
	
    handleOnPlanningChange(n) {
        this.setState({ planning: n });
    }

    handleOnCommunicationChange(n) {
        this.setState({ communication: n });
    }

    handleOnCommentChange(event) {
        event.preventDefault();
        this.setState({ comment: event.target.value });
    }

    nextPage() {
        let self = this;
        self.setState({ animation: 'fadeOut' });
        setTimeout(function () {
            self.setState((prevState) => { return { animation: 'fadeIn', error: '', page: prevState.page + 1 } });
        }, 500);
    }

    handleOnBackClick(event) {
        event.preventDefault();
        if (this.state.page === 1) {
            this.props.onBackClick(event);
        } else {
            this.setState({ page: this.state.page - 1 });
        }
    }

    handleOnSubmit(event) {
        event.preventDefault();
        let self = this;

        let payload = new FormData();
        payload.append('total', this.state.total);
        payload.append('communication', this.state.communication);
        payload.append('planning', this.state.planning);
        payload.append('comment', this.state.comment);

        //console.log(payload);

        fetch('https://alfatauristudio.it/contact-feedback.php', {
            method: 'POST',
            body: payload
        })
		.then((data) => data.json())
		.then((data) => {
			if (data.success) {
				console.log('success');
				self.props.onSubmit(event);
			}
		})
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12 ml-4 mb-4">
                        <button className="btn backward-arrow-container p-0" onClick={this.handleOnBackClick}
                            onMouseOver={this.props.onBackMouseOver} onMouseOut={this.props.onBackMouseOut}>
                            <img className="backward-arrow" alt="Backward form arrow" src={require('../../images/backward-gray.png')} />
                        </button>
                    </div>
                </div>

                <div className="row justify-content-start">
                    <div className="col-10 col-lg-10 offset-lg-1 pt-5 form-container">
                        <div className={'animated ' + this.state.animation}>
                                <form key='form1' className='at-info-form'>
                            {this.state.page === 1 ? (
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-5">
											<h4 className="mb-5">Valuta il servizio/prodotto che ti abbiamo fornito<span className="orange">.</span></h4>
											<ATSliderDots horizontal dotsNumber={10} activeDot={this.state.total}
											size="10px" onDotClick={this.handleOnTotalChange}
											style={{ position: 'relative', width: '100%' }}/>
											<Button className="start mt-5 mr-0" onClick={this.nextPage}>Avanti</Button>
                                        </div>
                                    </div>
                            ) : this.state.page === 2 ? (
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-5">
											<h4 className="mb-5">Come valuteresti la nostra organizzazione?</h4>
											<ATSliderDots horizontal dotsNumber={10} activeDot={this.state.planning}
											size="10px" onDotClick={this.handleOnPlanningChange}
											style={{ position: 'relative', width: '100%' }}/>
											<Button className="start mt-5 mr-0" onClick={this.nextPage}>Avanti</Button>
                                        </div>
                                    </div>
                            ) : this.state.page === 3 ? (
                                    <div className="form-row">
                                        <div className="form-group col-12 mb-5">
											<h4 className="mb-5">Come valuteresti la nostra comunicazione?</h4>
											<ATSliderDots horizontal dotsNumber={10} activeDot={this.state.communication}
											size="10px" onDotClick={this.handleOnCommunicationChange}
											style={{ position: 'relative', width: '100%' }}/>
											<Button className="start mt-5 mr-0" onClick={this.nextPage}>Avanti</Button>
                                        </div>
                                    </div>
                            ) : (
								<div className="form-row">
									<div className="form-group col-12 mb-5">
										<h4 className="mb-5">Cosa miglioreresti?</h4>
										<textarea className="form-control" id="textarea" rows="5"
											placeholder="Lasciaci un tuo commento..." onChange={this.handleOnCommentChange}></textarea>
										<Button className="start mt-5 mr-0" onClick={this.nextPage}>Avanti</Button>
									</div>
								</div>
							)}
							</form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FeedbackForm;